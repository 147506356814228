// import images
import logo from './img/logo.png'

// general
export const WEBSITE_NAME = "AquaBite"

// images
export const LOGO = logo;

// links navbar
export const MENU_LINKS = [
  // { name: "Home", to: '#welcome' },
];

export const FOOTER = {
  socialmedia: {
    telegram: {
      show: false,
      link: 'https://t.me/+MtTZKdNaEllmY2Mx'
    },
    twitter: {
      show: true,
      link: 'https://twitter.com/AquaBiteNFT'
    },
    discord: {
      show: false,
      link: 'https://discord.gg/Plethoramoons'
    },
    youtube: {
      show: false,
      link: 'http://www.google.de'
    },
    opensea: {
      show: false,
      link: 'https://opensea.io/collection/plethoramoonsnft'
    },
    github: {
      show: false,
      link: 'http://www.google.de'
    },
    reddit: {
      show: false,
      link: 'http://www.google.de'
    },
    medium: {
      show: true,
      link: 'https://medium.com/@exoticmetaverse'
    },


  }
}
