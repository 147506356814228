import React, { useState } from 'react';
import { useEffect } from 'react';
import { CONTRACT, CONTRACT_ABI, WEIPRICE } from '../data/ChainData';
import { TransactionButton } from '../web3/components';
import { useConnectedAccount, useConnectedWeb3, useEmptyWeb3, useTriggerEvent } from '../web3/hooks';

const Mint = () => {
  const [amount, setAmount] = useState(1)
  const web3 = useEmptyWeb3();
  const { account, } = useConnectedAccount();

  const [contract, setContract] = useState(null);
  const [userConfig, setUser] = useState({ canMint: false, price: 0, amount: 0, canClaim: false, claimAmount: 0 })
  const [select, setSelect] = useState(<></>)

  const {event, } = useTriggerEvent();

  useEffect(() => {

    let c = new web3.eth.Contract(CONTRACT_ABI, CONTRACT);
    setContract(c);

    const init = async (instance) => {
      const info = await instance.methods.getInfo(account).call();
      const u = {
        canMint: info[0],
        price: info[1],
        amount: info[2],
        canClaim: info[3],
        claimAmount: info[4]
      }
      setUser(u);
      console.log(u)

      const s = <select name="amount" id="amount" onChange={selectHandler}>
        {Array.apply(null, { length: u.amount }).map((a, i) => (<option id={i+1} value={i+1}>&nbsp;{i+1}&nbsp;</option>))}
      </select>
      setSelect(s);
    }

    if (account) {
      init(c);
    }

  }, [account, event])

  useEffect(() => {},[select, event])

  const selectHandler = (e) => {
    setAmount(e.target.value)
  }

  if (!account) return (<div>please connect your wallet</div>)
  if (account && (!userConfig.canMint && !userConfig.canClaim)) return (<div>Wallet not whitelisted or mint limit reached.</div>)
  return (
    <div style={{marginTop: "70px"}}>
      {userConfig.canMint && userConfig.amount > 0 && <div>
      <div>
        <label for="amount">Select amount: &nbsp; </label>
        {select}
      </div>
      <div>
        Price: {web3.utils.fromWei((userConfig.price*amount).toString(), 'ether')} ETH
      </div>
      <br />
      <div>
        <TransactionButton
          address={CONTRACT}
          abi={CONTRACT_ABI}
          method={'mint'}
          args={[account, amount]}
          confirmations={1}
          text={'Mint'}
          value={amount * userConfig.price}
        />
      </div>
      </div>}
      {userConfig.canClaim && userConfig.claimAmount > 0 && 
      <div style={{marginTop: "15px"}}>
        <TransactionButton
          address={CONTRACT}
          abi={CONTRACT_ABI}
          method={'claim'}
          args={[userConfig.claimAmount]}
          confirmations={1}
          text={'Claim free NFTs'}
          />
      </div>}
    </div>
  );
}

export default Mint;