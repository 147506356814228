import React from 'react';
import WelcomeHero from '../heros/WelcomeHero';

const Home = () => {
  return (
    <div>
      <WelcomeHero />
    </div>
  );
}

export default Home;